.select-datos {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
  padding: 0.5rem;
}
.add-dialog-content > .MuiFormControl-root {
  margin-bottom: 1rem;
}