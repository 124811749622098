#location-group-label {
  /* font-weight: bold !important; */
  margin-top: 0.5rem;
  margin-left: 1rem;
} 
.location-group {
  display: flex;
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
}
.location-group > div {
  margin: 1rem;
}