#coeficient-group-label {
  font-weight: bold !important;
  margin-top: 2rem;
} 
.coeficient-group {
  display: grid;
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: 50% 50%;
  grid-column-gap: 5%;
  padding: 1rem;
}