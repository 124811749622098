.toolbar {
  background-color: #494949;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 3rem;
  box-shadow: 3px 0 10px rgb(0 0 0 / 20%);
  z-index: 3000;
  position: fixed;
}
.toolbar button img {
  width: 1.4rem;
  height: 1.4rem;
}
.toolbar button {
  padding: 0;
  width: 100%;
  height: 2.5rem;
  min-width: 0;
  line-height:0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;

}
.toolbar button:hover {
  background-color: #343434;
}
.menu-selected-mark {
  width: 0.2rem;
  height: 100%;
  background-color: aqua;
}
.toolbar-menu {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button-settings {
  position: absolute !important;
  bottom: 10px !important;
}