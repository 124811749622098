.sector-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 95%;
  padding: 2rem;
}
.description-sector {
  background-color: #e3e3e3;
  margin-top: 2rem;
  padding: 1rem;
}
.name-sector {
  margin-top: 1rem;
  margin-left: 2rem;
}
.head-sector button img {
  width: 1.4rem;
  height: 1.4rem;
}
.head-sector {
  display: flex;
  justify-content: space-between;
}
.head-sector button {
  min-width: auto;
}