.btn-add-insar {
  display: flex !important;
  margin-top: 1rem !important;
  margin-left: auto !important;
}
.table-captura .MuiBox-root{
  padding-top: 0.4rem !important;
  /* max-width: 88vw; */
  padding-bottom: 20px !important;
}
.table-periodo .MuiBox-root{
  padding-top: 0.4rem !important;
}
.grupo-escalas {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.escala-min {
  height: 1rem;
  /* background-color: dodgerblue; */
}
.escala-max {
  height: 1rem;
  width: 2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-color: brown;
}
.grupo-datos-escala {
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  min-width: 3rem;
}
.datos-escala {
  display: flex;
  justify-content: space-between;
}