.escala-group {
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
  padding: 0.5rem;
  padding-right: 1rem;
}
.escala {
  display: flex;
  align-items: center;
}

.escala > .MuiFormControl-root  {
margin: 0.5rem;
width: 10rem;
}
.color-picker {
  height: 1rem;
  width: 1rem;
  /* background-color: blue; */
  margin: 0.5rem;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
  left: 11.8rem;
  top: -0.2rem;
}