.MuiDrawer-root, .MuiDrawer-root .MuiPaper-root {
  margin-left: 3rem;
  /* transition: none !important; */
}

.MuiDrawer-paper {
  background-color: #e7e7e7 !important;
  overflow-y: unset !important;
}
.tree-menu {
  margin: 1rem 0.2rem 1rem 1rem;
  height: 100vh;
}
.menu-name {
  background-color: #343434;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 18rem;
}
.tree-menu > ul {
  height: calc(100% - 4.5rem);
  padding-right: 2rem;
  /* overflow-x: unset; */
}
.title-drawer-menu {
  color: white;
  margin-left: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.menu-button-header button img {
  width: 1rem;
  height: 1rem;
}
.menu-button-header .MuiButton-root {
  margin: 0 1rem 0 0 !important;
  padding: 0rem !important;
  min-width: auto !important;
  line-height: normal !important;
}