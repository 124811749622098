#umbral-configuration-label, #geo-ubication-label {
  font-weight: bold !important; 
  margin-top: 0.5rem;
  margin-left: 1rem;
} 
.geo-ubication-group {
  /* display: flex !important;
  flex-direction: column; */
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.coordinates {
  display: flex;
  /* justify-content: space-between; */
}
.coordinates > div {
  margin: 0.5rem;
}
.umbral-configuration-group {
  display: grid;
  border: 1px solid #b2b2b2;
  border-radius: 0.25rem;
  grid-template-columns: 48% 48%;
  grid-template-rows: 50% 50%;
  grid-column-gap: 4%;
  padding: 1rem;
}