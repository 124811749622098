.menu-card-item {
    width: 15rem;
    height: fit-content;
    margin: 0.5rem 0.5rem 0rem 0.5rem;
}
.menu-card-header {
    padding: 0.3rem !important;
    margin-right: 0.5rem;
}
.menu-card-setting {
    display: flex;
    justify-content: center;
    padding-top: 0.25rem;
}

.menu-info {
    display: flex;
    flex-direction: column;
}

.dashboard-info {
    height: 95%;
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-info-card {
    width: 89%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    margin-left: 150px;
}

.dashboard-info-card-piezometro {
    border-radius: 4px;
    height: 95%;
    width: 100%;
    background: white;
    box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.5);
    color: #6B6B6B;
}

.dashboard-card-content {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-around;
}

.dashboard-card-left {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-around;
}

.dashboard-card-left-top {
    height: 25.8vh;
    display: flex;
    flex-direction: row;
}

.dashboard-card-leftTop-left {
    margin-right: 5px;
}

.dashboard-card-leftTop-rigth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    align-content: space-between;
}

.dashboard-card-leftTop-rigthInside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    align-content: space-between;
}

.dashboard-card-leftTop-rigthSkeleton {
    display: flex;
    flex-direction: column;
}

.dashboard-card-left-down {
    margin-top: 8px;
    height: 32vh;
}

.dashboard-card-left-down-card {
    height: 27vh;
    scroll-behavior: auto;
    overflow-y: auto;
    max-height: 27vh;
    font-size: 0.85rem;
}

.dashboard-accordion-state {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    background-color: #FAFAFA;
    padding: 5px 0;
}
.dashboard-accordion-state-etiqueta {
    width: 20%;
    min-width: 20px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordion-details {
    padding: 8px 10px 10px !important;
}

.divider-resumen {
    padding-top: 6px;
    max-width: 90%;
    border-color: rgba(0, 0, 0, 0.25) !important;
}

.dashboard-accordion-state-resumen-up {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.piezometro-state-normal {
    height: 19.8vh;
    width: 12vw;
    background-color: #78FF86;
    font-weight: bold;
}

.piezometro-state-normal-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 5rem;
    margin-top: -65px;
}

.piezometro-state-ndefault {
    height: 19.8vh;
    width: 12vw;
    background-color: #F1F1F1;
    font-weight: bold;
}

.piezometro-state-ndefault-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 5rem;
    margin-top: -65px;
}

.piezometro-state-others-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 2.0rem;
    margin-top: -28px;
}

.piezometro-state-advert {
    height: 7vh;
    width: 7.5vw;
    background-color: #F7FF9F;
    font-weight: bold;
}

.piezometro-state-alert {
    height: 7vh;
    width: 7.5vw;
    background-color: #FFB783;
    font-weight: bold;
}

.piezometro-state-alarm {
    height: 7vh;
    width: 7.5vw;
    background-color: #FF8383;
    font-weight: bold;
}

.piezometro-state-offline {
    height: 7vh;
    width: 7.5vw;
    background-color: #27CBFF;
    font-weight: bold;
}

.piezometro-state-default {
    height: 7vh;
    width: 7.5vw;
    background-color: #F1F1F1;
    font-weight: bold;
}

.dashboard-card-right {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: flex-start;
    padding-left: 5px;
}

.dashboard-card-right-top {
    height: 22vh;
    display: flex;
    flex-direction: row;
}
.dashboard-card-right-down {
    margin-top: 8px;
    height: 32vh;
}

.icon-img {
    width: 24px;
    height: 24px;
    text-align: center;
}

.dashboard-info-card-acelerometro {
    margin-top: 5px;
    border-radius: 4px;
    height: 95%;
    width: 98%;
    padding: 0 15px 10px 15px;
    background: white;
    box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.5);
    color: #6B6B6B;
}

.card-acelerometros {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px !important;
}

.card-acelerometros-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
    align-items: center;
    align-content: end;
    font-size: 10px;
    height: 2vh;
}

.card-acelerometros-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: end;
    font-size: 22px;
    font-weight: bold;
    height: 6vh;
}

.card-acelerometros-footer {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    font-size: 10px;
    height: 2vh;
}

.dashboard-info-card-order {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
}

.dashboard-info-card-first {
    margin-top: 5px;
    border-radius: 4px;
    height: 95%;
    width: 98%;
    padding: 0 15px 10px 15px;
    background: white;
    box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.5);
    color: #6B6B6B;
}

.dashboard-info-card-second {
    margin-top: 5px;
    border-radius: 4px;
    height: 95%;
    width: 98%;
    padding: 0 15px 10px 15px;
    background: white;
    box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.5);
    color: #6B6B6B;
}

.card-sismos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px !important;
}

.card-sismos-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
    align-items: center;
    align-content: end;
    font-size: 10px;
    height: 2vh;
}

.card-sismos-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: end;
    font-size: 28px;
    font-weight: bold;
    height: 8vh;
}

.MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2) !important;
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
}